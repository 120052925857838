import React, { useContext, useEffect } from 'react'
import AuthContext from '../contexts/AuthContext';
import { Container, Col, Row } from 'react-bootstrap'
import { updatePageTitle, updatePageDescription } from '../components/Helpers'



function SignOut() {
    const auth = useContext(AuthContext);

    useEffect(() => {
        updatePageTitle("Contact")
        updatePageDescription()
        logout()
    }, []);

    async function logout() {
        logout(() => auth.logout())
        window.location.replace("/")
    }

    return (
        <Container>
            <Row>
                <div className="page-width">
                    <h1>Sign Out</h1>
                    <br /><br />
                    <div>Signing you out...</div>
                    <br /><br />
                </div>
            </Row>
        </Container>
    )
}

export default SignOut