import { useContext, useState, useEffect } from 'react';
import { Container, Col, Row, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom"
import ListGroup from 'react-bootstrap/ListGroup';
import axios from "axios"
import { AiOutlineSend } from 'react-icons/ai';
import { IoCloseSharp } from "react-icons/io5";
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import AuthContext from '../../contexts/AuthContext';
import { Logout, generalPost, ResponseMessage } from '../../components/BaseFunctions'



export function CreateAccount() {
    const auth = useContext(AuthContext);
    const [showCreateAccount, setShowCreateAccount] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [submitButtonActive, setSubmitButtonActive] = useState(true);
    const [sending, setSending] = useState(false);
    const [sendButton, setSendButton] = useState(<CreateAccountSendButton_Send />);
    const [agreeCheckbox, setAgreeCheckbox] = useState(false);
    const email_pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const password_pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])\S{8,64}$/

    const agreeCheckboxHandleChange = () => {
        setAgreeCheckbox(!agreeCheckbox);
    };

    const [show, setShow] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repassword, setRePassword] = useState("");
    const [passwordMessage, setPasswordMessage] = useState('');
    const [pwSubmitButton, setPwSubmitButton] = useState('Submit');


    const handleEmail = (event) => {
        var value = event.target.value;
        setEmail(value.trim())
    }

    const handlePassword = (event) => {
        var value = event.target.value;
        setPassword(value.trim())
    }

    const handleRePassword = (event) => {
        var value = event.target.value;
        setRePassword(value.trim())
    }

    function CreateAccountSendButton_Send() {

        if (disabled) {
            return (
                <>
                    <div><span className="bold">Incomplete</span></div>
                </>
            );
        } else {

            return (
                <>
                    <div><span className="bold">Send</span> <AiOutlineSend /></div>
                </>
            );
        }
    }

    function formValidation() {
        setDisabled(true);

        if (!password.match(password_pattern)) {
            setPasswordMessage("Password requirements: 8+ characters, 1 lowercase, 1 uppercase, 1 number, and 1 special character")
        }
        else if (password.length > 0 && password.length < 7) {
            setPasswordMessage("Password is too short")
        }
        else if (password !== repassword) {
            setPasswordMessage("Passwords don't match!")
        } else {
            setPasswordMessage('')
        }

        if (email.length > 4 && password.length > 7 && agreeCheckbox == true && password === repassword) {

            if (email.match(email_pattern) && password.match(password_pattern)) {
                setDisabled(false);
            }


        }

    }

    function ButtonDefaults() {
        setSendButton(<CreateAccountSendButton_Send />)
        setAgreeCheckbox(false)
        //setShow(false);
        //setEmail("");
        //setPassword("");
        //setRePassword("");
        //setPasswordMessage('');
    }

    useEffect(() => {
        formValidation()
        setSendButton(<CreateAccountSendButton_Send />)
    }, [password, repassword, email, agreeCheckbox, disabled])




    function CreateAccountProcess(email, password) {

        axios.defaults.withCredentials = true

        const formData = { 'email': email, 'password': password, 'password_confirm': repassword };

        const createAccount = async () => {
            let response = await generalPost(formData, 'user/create')

            if (response.type == "success") {

                ResponseMessage(response)
                ButtonDefaults()
                setShowCreateAccount(false)

            } else { ResponseMessage(response); ButtonDefaults() }

        }
        createAccount()


    }




    if (auth.authentication === 0) {

        return (
            <>
                <Link className='btn btn-secondary link' aria-label="Create Account" onClick={() => { setShowCreateAccount(true) }}>
                    Create Account
                </Link>

                <Modal
                    show={showCreateAccount}
                    size="lg"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Create Account</Modal.Title>
                        <div className="btn btn-primary close-btn" aria-label="Close" onClick={() => { setShowCreateAccount(false) }}><IoCloseSharp /></div>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={12}>

                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Email" onChange={handleEmail} />
                                    <br />
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control name="password" type="password" placeholder="Password" onChange={handlePassword} />
                                    <br />
                                    <Form.Label>Retype Password</Form.Label>
                                    <Form.Control type="password" placeholder="Password" onChange={handleRePassword} />

                                    <br />
                                    {passwordMessage}
                                    <br />
                                </Col>
                            </Row>
                        </Container>

                    </Modal.Body>
                    <Modal.Footer>
                        <div><label className="smallText">
                            <input
                                className="pointer checkbox"
                                type="checkbox"
                                checked={agreeCheckbox}
                                onChange={agreeCheckboxHandleChange}
                                name="agree-checkbox"
                            /> I agree to the <Link className="link pointer" to="/legal/privacy-policy" onClick={() => { setShowCreateAccount(false) }}>Privacy Policy</Link> and <Link className="link pointer" to="/legal/terms-and-conditions" onClick={() => { setShowCreateAccount(false) }}>Terms and Conditions</Link> of this Site.
                        </label></div>
                        <Button className="left" variant="secondary" onClick={() => { setShowCreateAccount(false) }}>
                            Cancel
                        </Button>
                        <Button
                            className='submit-btn btn btn-primary'
                            disabled={disabled}
                            type='submit'
                            onClick={() => {
                                setSendButton(<Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>); setSubmitButtonActive('disabled'); CreateAccountProcess(email, password, repassword)
                            }}
                        >
                            {sendButton}
                        </Button>
                    </Modal.Footer>
                </Modal>

            </>
        );

    }

}

export default CreateAccount;