import { useContext, useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import axios from "axios"
import { Button, Modal, Container, Col, Row, Form, Spinner } from 'react-bootstrap';
import { IoCloseSharp } from "react-icons/io5";
import { generalPost, ResponseMessage, signinPost } from '../../components/BaseFunctions'
import AuthContext from '../../contexts/AuthContext';
import PasswordReset from './PasswordReset';



export function SignIn() {
    const auth = useContext(AuthContext);
    const [showSignin, setShowSignin] = useState(false);
    const handleCloseSignin = () => setShowSignin(false);
    const handleShowSignin = () => setShowSignin(true);

    const [show, setShow] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [tempCode, setTempCode] = useState("");
    const [signInButtonActive, setSignInButtonActive] = useState('disabled');
    const [signInButton, setSignInButton] = useState('Sign in');
    const [resetPassword, setResetPassword] = useState(false);
    const [showEmailValidation, setShowEmailValidation] = useState(false);
    const email_pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const password_pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])\S{8,64}$/


    const handlePassword = (event) => {
        var value = event.target.value;
        setPassword(value.trim())
    }

    const handleTempCode = (event) => {
        var value = event.target.value;
        setTempCode(value.trim())
    }

    function formValidation() {
        setSignInButtonActive('disabled');

        if (email.length > 4 && password.length > 7) {

            if (email.match(email_pattern) && password.match(password_pattern)) {
                setSignInButtonActive('enabled');
            }

        }

    }

    useEffect(() => {
        formValidation()
    }, [password, email])


    function DefaultButtons() {
        setResetPassword(false)
        setSignInButtonActive('disabled')
        setSignInButton('Sign in')
    }

    function Authenticate(email, password) {

        axios.defaults.withCredentials = true

        const formData = { 'username': email, 'password': password };

        const sendAPICreds = async () => {
            let response = await signinPost(formData, 'auth/login')

            if (response.type == "success") {
                if (response.message === 'success') {
                    auth.login()
                    DefaultButtons()
                    setSignInButtonActive('active')
                    setShowSignin(false)
                } else {
                    ResponseMessage(response)
                    DefaultButtons()
                    setSignInButtonActive('active')
                }

            } else {
                if (response.message.includes("Email validat") || response.message.includes("email validat")) {
                    setShowEmailValidation(true)
                }
                ResponseMessage(response)
                DefaultButtons()
                setSignInButtonActive('active')
            }

        }
        sendAPICreds()
    };


    function EmailValidation(email, tempCode) {

        axios.defaults.withCredentials = true

        const formData = { 'email': email, 'temp_code': tempCode };

        const run = async () => {
            let response = await generalPost(formData, 'user/validate-email')

            if (response.type == "success") {
                ResponseMessage(response)
                setShowEmailValidation(false)
                DefaultButtons()
                setSignInButtonActive('active')
                setSignInButton(<Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>); setSignInButtonActive('disabled'); Authenticate(email, password)
            } else {
                ResponseMessage(response)
                DefaultButtons()
                setSignInButtonActive('active')
            }

        }
        run()
    };


    if (resetPassword) {

        return (
            <>
                <Link className='btn btn-primary link' aria-label="Signin" onClick={handleShowSignin}>
                    Sign in
                </Link>

                <Modal
                    show={show}
                    size="md"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Reset Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>Coming soon...</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="center">
                            <Button variant="secondary" onClick={() => { DefaultButtons() }}>
                                Cancel
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    if (showEmailValidation === true) {

        return (
            <>
                <Link className='btn btn-primary link' aria-label="Signin" onClick={handleShowSignin}>
                    Sign in
                </Link>

                <Modal
                    show={showSignin}
                    onHide={handleCloseSignin}
                    size="md"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Email Validation</Modal.Title>
                        <div className="btn btn-primary close-btn" aria-label="Close" onClick={handleCloseSignin}><IoCloseSharp /></div>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control name="email" type="email" autoComplete="email" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value.trim())} />
                                <br />
                                <Form.Label>Temp Code</Form.Label>
                                <Form.Control name="temp-code" type="text" placeholder="Temp Code" value={tempCode} onChange={handleTempCode} />
                            </div>
                            <Button variant="primary" aria-label="Signin" onClick={() => {
                                setSignInButton(<Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>); setSignInButtonActive('disabled'); EmailValidation(email, tempCode)
                            }} className={signInButtonActive}>
                                {signInButton}
                            </Button>
                            <br /><br />

                            <Modal.Footer>
                                <div className="center">
                                    <Link className="btn btn-secondary" onClick={() => { setResetPassword(true) }}>
                                        Reset Password
                                    </Link>
                                </div>
                            </Modal.Footer>

                        </div>

                    </Modal.Body>
                </Modal>
            </>
        )
    }

    return (
        <>

            <Link className='btn btn-primary link' aria-label="Signin" onClick={handleShowSignin}>
                Sign in
            </Link>

            <Modal
                show={showSignin}
                onHide={handleCloseSignin}
                size="md"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Sign in</Modal.Title>
                    <div className="btn btn-primary close-btn" aria-label="Close" onClick={handleCloseSignin}><IoCloseSharp /></div>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <Form>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control id="email" name="email" type="email" autoComplete="username" placeholder="Email" value={email} onChange={(event) => setEmail(event.target.value.trim())} />
                                    <br />
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control name="password" type="password" autoComplete="current-password" placeholder="Password" value={password} onChange={handlePassword} />
                                </Form>
                            </Col>
                        </Row>
                        <br /><br />

                        <Modal.Footer>

                            <Button variant="primary" onClick={() => {
                                setSignInButton(<Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>); setSignInButtonActive('disabled'); Authenticate(email, password)
                            }} className={signInButtonActive}>
                                {signInButton}
                            </Button>


                        </Modal.Footer>
                        <PasswordReset />
                    </Container>

                </Modal.Body>
            </Modal>
        </>
    )


}

export default SignIn;