import { useContext, useState, useEffect } from 'react';
import { Button, Modal, Container, Col, Row, Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom"
import ListGroup from 'react-bootstrap/ListGroup';
import axios from "axios"
import { AiOutlineSend } from 'react-icons/ai';
import { IoCloseSharp } from "react-icons/io5";
import Form from 'react-bootstrap/Form';
import AuthContext from '../../contexts/AuthContext';
import { generalPost, ResponseMessage } from '../../components/BaseFunctions'



export function PasswordReset() {
    const auth = useContext(AuthContext);
    const [showPasswordReset, setShowPasswordReset] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [sendButton, setSendButton] = useState(<PasswordResetSendButton_Send />);
    const [agreeCheckbox, setAgreeCheckbox] = useState(false);
    const email_pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const password_pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])\S{8,64}$/

    const agreeCheckboxHandleChange = () => {
        setAgreeCheckbox(!agreeCheckbox);
    };

    const [email, setEmail] = useState("");
    const [tempCode, setTempCode] = useState("");
    const [password, setPassword] = useState("");
    const [repassword, setRePassword] = useState("");
    const [passwordMessage, setPasswordMessage] = useState('');


    const handleEmail = (event) => {
        var value = event.target.value;
        setEmail(value.trim())
    }

    const handleTempCode = (event) => {
        var value = event.target.value;
        setTempCode(value.trim())
    }

    const handlePassword = (event) => {
        var value = event.target.value;
        setPassword(value.trim())
    }

    const handleRePassword = (event) => {
        var value = event.target.value;
        setRePassword(value.trim())
    }

    function PasswordResetSendButton_Send() {

        if (disabled) {
            return (
                <>
                    <div><span className="bold">Incomplete</span></div>
                </>
            );
        } else {

            return (
                <>
                    <div><span className="bold">Send</span> <AiOutlineSend /></div>
                </>
            );
        }
    }

    function formValidation() {
        setDisabled(true);

        if (!password.match(password_pattern)) {
            setPasswordMessage("Password requirements: 8+ characters, 1 lowercase, 1 uppercase, 1 number, and 1 special character")
        }
        else if (password.length > 0 && password.length < 7) {
            setPasswordMessage("Password is too short")
        }
        else if (password !== repassword) {
            setPasswordMessage("Passwords don't match!")
        } else {
            setPasswordMessage('')
        }

        if (email.length > 4 && password.length > 7 && password === repassword) {

            if (email.match(email_pattern) && password.match(password_pattern)) {
                setDisabled(false);
            }


        }

    }

    function ButtonDefaults() {
        setSendButton(<PasswordResetSendButton_Send />)
    }

    useEffect(() => {
        formValidation()
        setSendButton(<PasswordResetSendButton_Send />)
    }, [password, repassword, email, disabled])



    function GenerateTempCode(email) {

        ResponseMessage({ "message": "Generating a new temp code...", "type": "info" })

        axios.defaults.withCredentials = true

        const formData = { 'email': email };

        const run = async () => {
            let response = await generalPost(formData, 'user/generate-temp-code')

            if (response.type == "success") {

                ResponseMessage(response)
                ButtonDefaults()

            } else { ResponseMessage(response); }

        }
        run()

    }

    function PasswordResetProcess(email, tempCode, password, repassword) {

        axios.defaults.withCredentials = true

        const formData = { 'email': email, 'temp_code': tempCode, 'password': password, 'password_confirm': repassword };

        const run = async () => {
            let response = await generalPost(formData, 'user/reset-password')
            if (response.type == "success") {
                ResponseMessage(response)
                ButtonDefaults()
                setShowPasswordReset(false)
                ResponseMessage({ "message": "You can sign in now.", "type": "success" })
            } else { ResponseMessage(response); ButtonDefaults() }

        }
        run()

    }




    if (auth.authentication === 0) {

        return (
            <>
                <div className="left">
                    <a className="pointer" onClick={() => { setShowPasswordReset(true) }}>
                        Reset Password
                    </a>
                </div>

                <Modal
                    show={showPasswordReset}
                    size="lg"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Reset Password</Modal.Title>
                        <div className="btn btn-primary close-btn" aria-label="Close" onClick={() => { setShowPasswordReset(false) }}><IoCloseSharp /></div>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={12}>

                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Email" onChange={handleEmail} />
                                    <br />
                                    <Form.Label>Temp Code</Form.Label>
                                    <Form.Control name="temp_code" type="text" placeholder="Temp Code (from your email)" onChange={handleTempCode} />
                                    <Link onClick={() => { GenerateTempCode(email) }}>Email a new temp code</Link>
                                    <br />
                                    <br />
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control name="password" type="password" placeholder="Password" onChange={handlePassword} />
                                    <br />
                                    <Form.Label>Retype Password</Form.Label>
                                    <Form.Control type="password" placeholder="Password" onChange={handleRePassword} />

                                    <br />
                                    {passwordMessage}
                                    <br />
                                </Col>
                            </Row>
                        </Container>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="left" variant="secondary" onClick={() => { setShowPasswordReset(false) }}>
                            Cancel
                        </Button>
                        <Button
                            className='submit-btn btn btn-primary'
                            disabled={disabled}
                            type='submit'
                            onClick={() => {
                                setSendButton(<Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>); PasswordResetProcess(email, tempCode, password, repassword)
                            }}
                        >
                            {sendButton}
                        </Button>
                    </Modal.Footer>
                </Modal>

            </>
        );

    }

}

export default PasswordReset;