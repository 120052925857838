import React, { useContext, useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { scrollToID } from './Helpers'

import { IoCloseSharp, IoHomeSharp } from "react-icons/io5";
import { IoIosPricetags } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineContactPhone } from "react-icons/md";
import CreateAccount from '../partials/auth/CreateAccount';
import SignIn from '../partials/auth/SignIn';
import Account from '../components/Account'
import AuthContext from '../contexts/AuthContext';


function Navbar() {
    const auth = useContext(AuthContext);
    const [showNav, setShowNav] = useState(false);
    const handleCloseNav = () => setShowNav(false);
    const handleShowNav = () => setShowNav(true);


    return (
        <>
            <div className="navbar-container">
                <div className="navbar-x">
                    <div className="companyLogoNav-container">
                        <Link to="/" aria-label="Logo" onClick={() => { scrollToID("topofpageRef") }}><img id="companyLogoNav" className="companyLogoNav pointer" alt="logo" title="Logo" src="https://cdn.sbhelper.com/sbhelper/images/site-logo-small.webp" /></Link>
                    </div>
                    <div className="navbar-button-container">
                        <Link className='btn btn-primary link' aria-label="Home" to="/" onClick={() => { scrollToID("topofpageRef") }}>
                            <IoHomeSharp />
                        </Link>
                        <Link className='btn btn-primary link' aria-label="Pricing" to="/pricing" onClick={() => { scrollToID("topofpageRef") }}>
                            <IoIosPricetags /> Pricing
                        </Link>
                        <Link className='btn btn-primary link' aria-label="Contact" onClick={() => { scrollToID("contact-section") }}>
                            <MdOutlineContactPhone /> Contact
                        </Link>
                        <Link className='btn btn-primary link hamburger-link' aria-label="Menu" onClick={handleShowNav}>
                            <GiHamburgerMenu />
                        </Link>
                    </div>
                </div >
            </div>


            <div className="navbar-container-2">
                <div className="navbar-x">
                    <div className="navbar-button-container">
                        {auth.authentication > 0 ?
                            <>
                                <Account />
                            </>
                            :
                            <>
                                <SignIn />
                                <CreateAccount />
                            </>
                        }
                    </div>
                </div >
            </div>



            <Offcanvas className="side-nav" show={showNav} onHide={handleCloseNav} placement="end" backdrop="static" scroll={false}>
                <Offcanvas.Header>
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                    <div className="btn btn-primary close-btn" aria-label="Close" onClick={handleCloseNav}><IoCloseSharp /></div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="side-nav-menu-container">
                        <a className="btn btn-primary link" href="/contact">Contact Us</a>
                        <a className="btn btn-primary link disabled" href="/contact">Submit Ticket</a>

                        <br /><br /><br />
                        <div><em>Placeholders while they are being built...</em></div>

                        <hr />
                        <div className="offcanvas-category-title bold">Platform Tools</div>
                        <Link className='btn btn-primary link' aria-label="Home" to="/dots" onClick={() => { setShowNav(false); scrollToID("topofpageRef") }}>Dots</Link>
                        <li>Notes</li>
                        <li>Scheduler</li>
                        <li>To-Do</li>
                        <hr />
                        <div className="offcanvas-category-title bold">Management</div>
                        <li>Finance</li>
                        <li>Inventory</li>
                        <li>Password</li>
                        <li>System</li>
                        <li>Ticket</li>
                        <li>Website</li>
                        <hr />
                        <div className="offcanvas-category-title bold">Tools For Fun</div>
                        <li>Movie Database</li>

                    </div>
                </Offcanvas.Body>
            </Offcanvas>

        </>
    );
}

export default Navbar;