import React, { useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { scrollToID } from '../components/Helpers'

const list = [{ "page-format": 1 }, { "title": "Automation", "content": "Most tasks can be performed automagically. Share your tasks with us and we can look into a new workflow.", "quote": "Freeing up your schedule." },
{ "title": "Reporting", "content": "Do you have data that needs collecting and analyzing? We'll securely store it in our database and generate a tailored report.", "quote": "Making data make sense." },
{ "title": "Recycling", "content": "We take pride in giving new life to old technology by repurposing, refurbishing, and parting-out. Our recycling program handles your outdated or broken computers, phones, and other electronics, ensuring that they are no longer your problem.", "quote": "One man's trash is another man's treasure." },
{ "title": "Pickup and Delivery", "content": "Simply put, we can pickup and deliver a job related item from a store to you.", "quote": "When you forget that doodad." },
{ "title": "Smart Appliances", "content": "Imagine a software where you can control and monitor your smart appliances remotely, set up automation routines, and receive real-time alerts. Whether it's adjusting the temperature before you arrive home, receiving notifications when an event is done, or adjusting your lighting with just a phrase, smart appliances make everyday tasks more convenient, efficient, and enjoyable.", "quote": "Is your fridge running?" },
{ "title": "Low-Voltage Electronics", "content": "Wide range of experience with low voltage electronics. Including diagnostics, repairs, installations, and modifications. When it can't be fixed, we can also recycle it.", "quote": "Number 5 is alive!" },
{ "title": "Multi-OS and Device Support", "content": "We're proficient in handling multiple operating systems and devices.", "quote": "[Imagine the long list here...]" },
{ "title": "Learning", "content": "In the dynamic world of technology, change is the only constant. Staying ahead means we're always learning, adapting, and evolving alongside the latest innovations.", "quote": "Did you know that the flux capacitor is over 38 years old? 🤯" },]


function TempPage() {

    const listItems = list.map((item) =>
        <>
            <div key={item.title} className="box-accent-title">{item.title}</div>
            <div className="box-accent-content">
                <div>{item.content}</div>
                <div className="box-accent-quote">{item.quote}</div>
            </div>
        </>
    );

    return (
        <>

            {
                list.slice(1).map((item, index) =>
                    <>
                        <Row><Col xs={12}>

                            <div className={index % 2 == 0 ? "box-accent-left" : "box-accent-right"}>
                                <div key={index} className="box-accent-title">{item.title}</div>
                                <div className="box-accent-content">
                                    <div>{item.content}</div>
                                    <div className="box-accent-quote">{item.quote}</div>
                                </div>

                            </div>
                        </Col></Row>
                    </>)
            }

        </>
    )
}

export default TempPage