import React, { useContext, useState, useEffect } from 'react'
import axios from "axios"
import { Button, Container, Col, Row, Modal, Form, Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom"
import { EmailTrimmed, scrollToID } from './Helpers'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { IoCloseSharp } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import AuthContext from '../contexts/AuthContext';
import { generalPost, ResponseMessage } from './BaseFunctions'


function Account() {

    const auth = useContext(AuthContext);
    const [showProfile, setShowProfile] = useState(false);
    const [showEditDetails, setShowEditDetails] = useState(false);
    const [userDetails, setUserDetails] = useState(auth.userDetails)
    const [submitButtonActive, setSubmitButtonActive] = useState('active')
    const [submitButton, setSubmitButton] = useState("Save Details")

    const [communicationToggles, setCommunicationToggles] = useState([])


    if (!auth.authentication > 0) { return }

    function saveDetails(e) {
        e.preventDefault();
        setSubmitButtonActive('disabled')
        setSubmitButton(<Spinner animation="border" role="status"></Spinner>);
        const data = new FormData(e.target)
        let formData = Object.fromEntries(data.entries())

        const run = async () => {
            let response = await generalPost(formData, 'auth/update-user-details')
            if (response.type == "success") {
                ResponseMessage(response)
                setSubmitButtonActive('active')
                setSubmitButton("Save Details")
                setShowEditDetails(false)
                auth.login()
                setUserDetails(formData)
            } else {
                ResponseMessage(response)
                setSubmitButtonActive('active')
            }

        }
        run()
    }


    async function updateCommunicationToggle(e, opt_in_field_type) {
        if (e.target.id.startsWith('email')) { var original_label = "Email" }
        if (e.target.id.startsWith('phone')) { var original_label = "Phone" }
        if (e.target.id.startsWith('text')) { var original_label = "Text" }
        document.getElementById(e.target.id).nextSibling.innerHTML = "Saving...";

        var field_name_id = e.target.value
        var is_opt_in = e.target.checked

        const formData = { "field_name_id": field_name_id, "is_opt_in": is_opt_in, "opt_in_field_type": opt_in_field_type };

        let response = await generalPost(formData, 'user/update-communications');
        if (response.type == "success") {
            getCommunicationToggles()
            ResponseMessage(response);
        } else {
            ResponseMessage(response);
        }




        document.getElementById(e.target.id).nextSibling.innerHTML = original_label

    }

    async function getCommunicationToggles() {
        const data = async () => {
            axios.defaults.withCredentials = true
            axios.get("https://api.sbhelper.com/user/communications")
                .then(res => {
                    if (res.status === 200) {
                        setCommunicationToggles(res.data)
                    }
                }).catch(error => {
                    return
                })
        }

        data()
    };


    return (
        <>

            <Link className='btn btn-primary link' aria-label="Profile" onClick={() => { setShowProfile(true); getCommunicationToggles() }}><FaUser /> Account</Link>

            <Modal
                show={showProfile}
                fullscreen={true}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header className="pageContainer">

                    <Modal.Title>Account <span className="label-dark">{auth.userDetails.user_id}</span><div className="label-dark">{EmailTrimmed(auth.userDetails.email)}</div></Modal.Title>

                    <Link className='btn btn-secondary link btn-logout' aria-label="Sign Out" onClick={() => { auth.logout() }}>
                        Sign out
                    </Link>


                    <div className="btn btn-primary close-btn" aria-label="Close" onClick={() => { auth.ping(), setShowProfile(false) }}><IoCloseSharp /></div>
                </Modal.Header>
                <Modal.Body className="pageContainer">

                    <div>In active development - updates are frequent...</div>
                    <br />

                    <Tabs
                        defaultActiveKey="profile"
                        id="account-tabs"
                        className="mb-3"
                        fill
                    >
                        <Tab eventKey="profile" title="Profile">
                            <Container>
                                <Row>
                                    <Col md={2} xs={0}>
                                    </Col>
                                    <Col md={8} xs={12}>
                                        <div className="card">
                                            <div className="card-title">About You</div>
                                            <div className="card-content">
                                                <div className="card-list-item-title bold">First name</div>
                                                <div className="card-list-item-value">{auth.userDetails.first_name ? auth.userDetails.first_name : "missing"}</div>

                                                <div className="card-list-item-title bold">Last name</div>
                                                <div className="card-list-item-value">{auth.userDetails.last_name ? auth.userDetails.last_name : "missing"}</div>

                                                <div className="card-list-item-title bold">Phone</div>
                                                <div className="card-list-item-value">{auth.userDetails.phone ? auth.userDetails.phone : "missing"}</div>

                                                <div className="card-list-item-title bold">Street Address Line 1</div>
                                                <div className="card-list-item-value">{auth.userDetails.street_address_1 ? auth.userDetails.street_address_1 : "missing"}</div>

                                                <div className="card-list-item-title bold">Street Address Line 2</div>
                                                <div className="card-list-item-value">{auth.userDetails.street_address_2 ? auth.userDetails.street_address_2 : "optional"}</div>

                                                <div className="card-list-item-title bold">City</div>
                                                <div className="card-list-item-value">{auth.userDetails.city ? auth.userDetails.city : "missing"}</div>

                                                <div className="card-list-item-title bold">State</div>
                                                <div className="card-list-item-value">{auth.userDetails.state ? auth.userDetails.state : "missing"}</div>

                                                <div className="card-list-item-title bold">Zip Code</div>
                                                <div className="card-list-item-value">{auth.userDetails.zip_code ? auth.userDetails.zip_code : "missing"}</div>
                                            </div>
                                            <br />
                                            <div className="btn btn-secondary" aria-label="Edit Details" onClick={() => { auth.ping(), setShowEditDetails(true) }}>Edit Details</div>
                                        </div>
                                    </Col>
                                    <Col md={2} xs={0}>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="communications" title="Communications">
                            <Container>
                                <Row>
                                    <Col md={2} xs={0}>
                                    </Col>
                                    <Col md={8} xs={12}>
                                        <div className="card">
                                            <div className="card-title">Communications Opt-In</div>
                                            <div className="card-content">
                                                {communicationToggles.map((record) => (
                                                    <div key={record.field_id}>
                                                        <div className="card-list-item-title bold">{record.field_name}</div>

                                                        {record.is_email_form == 1 ?

                                                            <>
                                                                <Form>
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id={"email_switch_" + record.field_id}
                                                                        name={record.field_name}
                                                                        value={record.field_id}
                                                                        label="Email"
                                                                        checked={record.is_email_opt_in == 1 ? true : false}
                                                                        onChange={(e, opt_in_field_type = "email") => { updateCommunicationToggle(e, opt_in_field_type) }}
                                                                    />
                                                                </Form>
                                                            </>
                                                            :
                                                            <div></div>
                                                        }
                                                        {record.is_phone_form == 1 ?

                                                            <>
                                                                <Form>
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id={"phone_switch_" + record.field_id}
                                                                        name={record.field_name}
                                                                        value={record.field_id}
                                                                        label="Phone"
                                                                        checked={record.is_phone_opt_in == 1 ? true : false}
                                                                        onChange={(e, opt_in_field_type = "phone") => { updateCommunicationToggle(e, opt_in_field_type) }}
                                                                    />
                                                                </Form>
                                                            </>
                                                            :
                                                            <div></div>
                                                        }
                                                        {record.is_text_form == 1 ?

                                                            <>
                                                                <Form>
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id={"text_switch_" + record.field_id}
                                                                        name={record.field_name}
                                                                        value={record.field_id}
                                                                        label="Text"
                                                                        checked={record.is_text_opt_in == 1 ? true : false}
                                                                        onChange={(e, opt_in_field_type = "text") => { updateCommunicationToggle(e, opt_in_field_type) }}
                                                                    />
                                                                </Form>
                                                            </>
                                                            :
                                                            <div></div>
                                                        }
                                                        <br />
                                                    </div>
                                                ))}




                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={2} xs={0}>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                    </Tabs>

                </Modal.Body>
            </Modal >


            <Modal
                show={showEditDetails}
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-secondary">
                    <Modal.Header>
                        <Modal.Title>Edit Details</Modal.Title>
                        <div className="btn btn-primary close-btn" aria-label="Close" onClick={() => { auth.ping(), setShowEditDetails(false) }}><IoCloseSharp /></div>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Form onSubmit={saveDetails}>
                                <Row>
                                    <Col xs={12}>
                                        <div className="card">
                                            <div className="card-title">About You</div>
                                            <div className="card-content">

                                                <Form.Label><div className="card-list-item-title bold">First name</div></Form.Label>
                                                <Form.Control name="first_name" type="text" placeholder="First name" defaultValue={userDetails.first_name} />

                                                <Form.Label><div className="card-list-item-title bold">Last name</div></Form.Label>
                                                <Form.Control name="last_name" type="text" placeholder="Last name" defaultValue={userDetails.last_name} />

                                                <Form.Label><div className="card-list-item-title bold">Phone</div></Form.Label>
                                                <Form.Control name="phone" type="text" placeholder="Phone" defaultValue={userDetails.phone} />

                                                <Form.Label><div className="card-list-item-title bold">Street Address Line 1</div></Form.Label>
                                                <Form.Control name="street_address_1" type="text" placeholder="Street Address Line 1" defaultValue={userDetails.street_address_1} />

                                                <Form.Label><div className="card-list-item-title bold">Street Address Line 2</div></Form.Label>
                                                <Form.Control name="street_address_2" type="text" placeholder="Street Address Line 2" defaultValue={userDetails.street_address_2} />

                                                <Form.Label><div className="card-list-item-title bold">City</div></Form.Label>
                                                <Form.Control name="city" type="text" placeholder="City" defaultValue={userDetails.city} />

                                                <Form.Label><div className="card-list-item-title bold">State</div></Form.Label>
                                                <Form.Control name="state" type="text" placeholder="State" defaultValue={userDetails.state} />

                                                <Form.Label><div className="card-list-item-title bold">Zip Code</div></Form.Label>
                                                <Form.Control name="zip_code" type="text" placeholder="Zip Code" defaultValue={userDetails.zip_code} />


                                            </div>
                                            <br />
                                            <Button variant="secondary" className={submitButtonActive} type="submit" name="submit" aria-label="Save Details">{submitButton}</Button>
                                        </div>
                                    </Col>

                                </Row>
                            </Form>
                        </Container>

                    </Modal.Body>
                </div>
            </Modal>

        </>
    )
}


export default Account;