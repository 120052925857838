import React, { Component } from 'react'
import Theme from './theme/Theme'
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import { updatePageTitle, updatePageDescription } from './components/Helpers'



function Init() {
  updatePageTitle()
  updatePageDescription()
  localStorage.setItem('frontendCurrentVersion', '0.6.5')
}



export default class App extends Component {

  render() {
    return (
      <>
        <link rel="prefetch" href="https://cdn.sbhelper.com/sbhelper/images/crater-lake.webp" as="image"></link>
        <link rel="prefetch" href="https://cdn.sbhelper.com/sbhelper/images/computer-repair.webp" as="image"></link>
        <link rel="prefetch" href="https://cdn.sbhelper.com/sbhelper/images/website-development.webp" as="image"></link>
        <link rel="prefetch" href="https://cdn.sbhelper.com/sbhelper/images/custom-development.webp" as="image"></link>
        <link rel="prefetch" href="https://cdn.sbhelper.com/sbhelper/images/pricing-learning.webp" as="image"></link>


        <Init />
        <ThemeProvider
          breakpoints={['lg', 'md', 'sm', 'xs']}
        >
          <Theme />

        </ThemeProvider>
      </>
    )
  }
}