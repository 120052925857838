import React, { useContext, useState, useEffect } from 'react'
import { Container, Col, Row, Spinner } from 'react-bootstrap'
import { AiOutlineSend } from 'react-icons/ai';
import AuthContext from '../contexts/AuthContext';
import { Link } from "react-router-dom";

import { generalPost, ResponseMessage } from '../components/BaseFunctions'
import { scrollToID } from '../components/Helpers'


function ContactForm() {
    const auth = useContext(AuthContext);
    const [disabled, setDisabled] = useState(true);
    const [sending, setSending] = useState(false);
    const [sendButton, setSendButton] = useState(<ContactFormSendButton_Send />);
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [emailSubject, setEmailSubject] = useState("");
    const [emailMessage, setEmailMessage] = useState("");
    const [agreeCheckbox, setAgreeCheckbox] = useState(false);

    const agreeCheckboxHandleChange = () => {
        setAgreeCheckbox(!agreeCheckbox);
    };


    useEffect(() => {
        if (auth.authentication > 0 && auth.userDetails.first_name && auth.userDetails.email) {
            setUserName(auth.userDetails.first_name)
            setUserEmail(auth.userDetails.email)
        }
    }, [auth])

    useEffect(() => {

        if (sending) {

        } else {
            formValidation();
            setSendButton(<ContactFormSendButton_Send />);
        }

    }, [disabled, userName, userEmail, emailSubject, emailMessage, agreeCheckbox])


    function ContactFormSendButton_Send() {

        if (disabled) {
            return (
                <>
                    <div><span className="bold">Fill out the form</span></div>
                </>
            );
        } else {

            return (
                <>
                    <div><span className="bold">Send</span> <AiOutlineSend /></div>
                </>
            );
        }
    }

    function ContactFormSendButton_Sending() {
        setSending(true)
        return (
            <>
                <div><span className="bold">Sending</span> <Spinner size="sm" /></div>
            </>
        );
    }


    const handleUserName = (event) => {
        var value = event.target.value;
        setUserName(value);
        formValidation();
    };


    const handleUserEmail = (event) => {
        var value = event.target.value;
        setUserEmail(value.trim());
        formValidation();
    };


    const handleEmailSubject = (event) => {
        var value = event.target.value;
        setEmailSubject(value.trim());
        formValidation();
    };


    const handleEmailMessage = (event) => {
        var value = event.target.value;
        setEmailMessage(value);
        formValidation();
    };

    function resetContactForm() {
        setUserName("");
        setUserEmail("");
        setEmailSubject("");
        setEmailMessage("");
        setAgreeCheckbox(false);

    }

    async function submitEmail(userName, userEmail, emailSubject, emailMessage) {
        setDisabled(true);
        setSendButton(ContactFormSendButton_Sending);
        const formData = { "user_name": userName, "user_email": userEmail, "email_subject": emailSubject, "email_message": emailMessage };
        let response = await generalPost(formData, 'email/form');

        ResponseMessage(response);
        if (response.type == "success") {
            resetContactForm();
            ResponseMessage({ "message": "Make sure to check spam folders for your email responses.", "type": "info" });
            setSending(false);
        } else {
            setDisabled(false);
            setSending(false);
        }

        if (response.type != "success" && response.type != "error") {
            ResponseMessage({ "message": "An error occurred", "type": "error" })
        }


        return
    }

    function formValidation() {
        setDisabled(true);

        if (userName.length > 1 && userEmail.length > 4 && emailSubject.length > 2 && emailMessage.length > 2 && agreeCheckbox == true) {
            if (userEmail.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
                setDisabled(false);
            }

        }
    }


    return (
        <>
            <div className='contact-form'>
                <Container fluid className="page-width">
                    <h2 className="section">Email Form</h2>
                    <Row>
                        <Col md={6} xs={12}>
                            <label>Name</label>
                            <input
                                type='text'
                                name='name'
                                className='form-control'
                                placeholder='Enter your name'
                                onChange={handleUserName}
                                value={userName}
                                required
                            ></input>

                            <br />
                        </Col>
                        <Col md={6} xs={12}>
                            <label>Email</label>
                            <input


                                className='form-control'
                                placeholder='Enter your email address'
                                onChange={handleUserEmail}
                                value={userEmail}
                                required
                            ></input>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <label>Subject</label>
                            <input
                                type='text'
                                name='subject'
                                className='form-control'
                                placeholder='Enter a subject'
                                onChange={handleEmailSubject}
                                value={emailSubject}
                                required
                            ></input>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={12}>
                            <label>Message</label>
                            <textarea
                                rows={3}
                                name='message'
                                className='form-control'
                                placeholder='Enter your message'
                                onChange={handleEmailMessage}
                                value={emailMessage}
                                required
                            ></textarea>

                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={12}>
                            <label className="smallText">
                                <input
                                    className="pointer checkbox"
                                    type="checkbox"
                                    checked={agreeCheckbox}
                                    onChange={agreeCheckboxHandleChange}
                                    name="agree-checkbox"
                                /> I agree to the <Link className="link pointer" to="/legal/privacy-policy" onClick={() => { scrollToID("page-container") }}>Privacy Policy</Link> and <Link className="link pointer" to="/legal/terms-and-conditions" onClick={() => { scrollToID("page-container") }}>Terms and Conditions</Link> of this Site.
                            </label>
                            <br />
                            <div>
                                <button
                                    className='submit-btn btn btn-primary'
                                    disabled={disabled}
                                    type='submit'
                                    onClick={() => { submitEmail(userName, userEmail, emailSubject, emailMessage) }}
                                >
                                    {sendButton}
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default ContactForm