import { useContext, useState, useEffect } from 'react'
import { Button, Container, Col, Row, Modal, Form, Spinner, Table } from 'react-bootstrap';
import AuthContext from '../../contexts/AuthContext';
import axios from "axios"
import { CiEdit, CiMedicalClipboard } from "react-icons/ci";
import { IoCloseSharp } from "react-icons/io5";
import { IoIosAdd } from "react-icons/io";

import { CopyToClipboard, generalPost, NotSignedIn, ResponseMessage, TimestampConverter } from '../BaseFunctions';


function Dots() {
    const auth = useContext(AuthContext);
    const [dotList, setDotList] = useState([{ "dot_id": "Loading..." }])
    const [createDotButton, setCreateDotButton] = useState(<span><IoIosAdd /> New Dot</span>)
    const [createDotButtonDisabled, setCreateDotButtonDisabled] = useState(false)
    const [showEditDot, setShowEditDot] = useState(false)
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)
    const [saveButton, setSaveButton] = useState("Save")
    const [dotSelected, setDotSelected] = useState({})
    const [showDeleteDot, setShowDeleteDot] = useState(false)
    const [deleteButton, setDeleteButton] = useState("Delete dot and all history")
    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false)

    async function GetDotList() {
        const data = async () => {
            axios.defaults.withCredentials = true
            axios.get("https://api.sbhelper.com/dot/get-dot-list")
                .then(res => {
                    if (res.status === 200) {
                        setDotList(res.data)
                    }
                }).catch(error => {
                    return
                })
        }

        data()
    };


    function deleteDot(e) {
        e.preventDefault();

        setDeleteButtonDisabled(true)
        setDeleteButton(<Spinner animation="border" role="status"></Spinner>);
        const data = new FormData(e.target)
        let formData = Object.fromEntries(data.entries())

        const run = async () => {
            let response = await generalPost(formData, 'dot/delete-dot')
            if (response.type == "success") {
                GetDotList()
                ResponseMessage(response)
                setDeleteButtonDisabled(false)
                setDeleteButton("Delete dot and all history")
                setShowDeleteDot(false)
                setShowEditDot(false)
            } else {
                ResponseMessage(response)
            }

            setShowDeleteDot(false)
            setDeleteButtonDisabled(false)
            setDeleteButton("Delete dot and all history")

        }

        run()
    }

    function saveDetails(e) {
        e.preventDefault();
        //auth.ping();

        setSaveButtonDisabled(true)
        setSaveButton(<Spinner animation="border" role="status"></Spinner>);
        const data = new FormData(e.target)
        let formData = Object.fromEntries(data.entries())

        const run = async () => {
            let response = await generalPost(formData, 'dot/update-note')
            if (response.type == "success") {
                GetDotList()
                ResponseMessage(response)
                setSaveButtonDisabled(false)
                setSaveButton("Save")
                setShowEditDot(false)
            } else {
                ResponseMessage(response)
            }

            setSaveButtonDisabled(false)
            setSaveButton("Save")

        }

        run()
    }

    function EditDot(dot) {
        setDotSelected(dot)
        setShowEditDot(true)
    }

    async function CreateDot() {
        const data = async () => {
            axios.defaults.withCredentials = true
            axios.post("https://api.sbhelper.com/dot/create-dot")
                .then(res => {
                    if (res.status === 200) {
                        ResponseMessage(res.data)
                        GetDotList()
                        setCreateDotButton(<span><IoIosAdd /> New Dot</span>)
                        setCreateDotButtonDisabled(false)
                    }
                }).catch(error => {
                    return
                })
        }

        //auth.ping();
        setCreateDotButton()
        if (auth.authentication > 0) {
            setCreateDotButtonDisabled(true)
            setCreateDotButton(<Spinner animation="border" role="status" className="disabled"></Spinner>)
            data()
        }
    };


    useEffect(() => {
        if (auth.authentication > 0 && !auth.doLogout) {
            GetDotList()
        }
    }, [auth]);

    if (!auth.authentication > 0) { return <NotSignedIn /> }

    return (
        <>

            <Container>
                <Row>
                    <h1>Dots</h1>
                </Row>
                <Row>
                    <Button variant="primary" disabled={createDotButtonDisabled} onClick={() => { CreateDot() }}>{createDotButton}</Button>
                </Row>
                <Row>
                    <h4>Your Dots</h4>
                    <Table responsive="sm" striped bordered hover>
                        <thead>
                            <tr><th>ID</th><th className="center">Views</th><th>Note</th><th>Created</th><th>Action</th></tr>
                        </thead>
                        <tbody>
                            {dotList.map((dot, index) =>

                                <tr key={index}>
                                    <td><div className="label-darker pointer" onClick={() => { CopyToClipboard("https://api.sbhelper.com/dot/" + dot.dot_id + ".png") }}><CiMedicalClipboard /> Copy dot link</div><div>{dot.dot_id}</div></td><td className="center">{dot.views}</td><td>{dot.note}</td><td className="smallText"><TimestampConverter timestamp={dot.added_timestamp} /></td><td><Button variant="primary" onClick={() => { auth.ping(); EditDot(dot) }}><CiEdit /> Edit</Button></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Row>

            </Container>


            <Modal
                show={showEditDot}
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-secondary">
                    <Modal.Header>
                        <Modal.Title>Edit Dot</Modal.Title>
                        <div className="btn btn-primary close-btn" aria-label="Close" onClick={() => { auth.ping(); setShowEditDot(false) }}><IoCloseSharp /></div>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Form onSubmit={saveDetails}>
                                <Row>
                                    <Col xs={12}>
                                        <div className="card">
                                            <div className="card-content">
                                                <br />
                                                <div><span className="bold">ID: </span><span className="label-darker">{dotSelected.dot_id}</span></div>
                                                <Form.Control name="dot_id" type="text" value={dotSelected.dot_id} readOnly hidden />
                                                <div><span className="bold">Created: </span><span className="label-darker"><TimestampConverter timestamp={dotSelected.added_timestamp} /></span></div>
                                                <div><span className="bold">Edited: </span><span className="label-darker"><TimestampConverter timestamp={dotSelected.updated_timestamp} /></span></div>
                                                <br />
                                                <Form.Label><div className="card-list-item-title bold">Note</div></Form.Label>
                                                <Form.Control name="note" type="text" placeholder="Enter a note" defaultValue={dotSelected.note} />
                                            </div>
                                            <br />
                                            <Button variant="secondary" disabled={saveButtonDisabled} type="submit" name="submit" aria-label="Save">{saveButton}</Button>
                                            <hr />
                                            <div>
                                                <Button variant="danger-secondary" onClick={() => { auth.ping(); setShowDeleteDot(true) }}>Delete</Button>
                                                <div className="btn btn-primary right" aria-label="Close" onClick={() => { auth.ping(); setShowEditDot(false) }}>Cancel</div>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            </Form>
                        </Container>

                    </Modal.Body>
                </div>
            </Modal>


            <Modal
                show={showDeleteDot}
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-secondary">
                    <Modal.Header>
                        <Modal.Title>Edit Dot</Modal.Title>
                        <div className="btn btn-primary close-btn" aria-label="Close" onClick={() => { auth.ping(); setShowDeleteDot(false) }}><IoCloseSharp /></div>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Form onSubmit={deleteDot}>
                                <Row>
                                    <Col xs={12}>
                                        <div className="card">
                                            <div className="card-content">
                                                <br />
                                                <div><span className="bold">ID: </span><span className="label-darker">{dotSelected.dot_id}</span></div>
                                                <Form.Control name="dot_id" type="text" value={dotSelected.dot_id} readOnly hidden />
                                                <div><span className="bold">Created: </span><span className="label-darker"><TimestampConverter timestamp={dotSelected.added_timestamp} /></span></div>
                                                <div><span className="bold">Edited: </span><span className="label-darker"><TimestampConverter timestamp={dotSelected.updated_timestamp} /></span></div>
                                                <div><span className="bold">Note: </span><span className="label-darker">{dotSelected.note}</span></div>
                                            </div>
                                            <br />
                                            <div>You are about to delete your dot and all history of {dotSelected.views} views.</div>
                                            <br />
                                            <div>Once deleted, you will not be able to restore/recover this dot and related content.</div>
                                            <hr />
                                            <div>
                                                <Button variant="danger-secondary" disabled={deleteButtonDisabled} type="submit" name="submit" aria-label="Delete" onClick={() => { auth.ping(); }}>{deleteButton}</Button>
                                                <div className="btn btn-primary right" aria-label="Close" onClick={() => { auth.ping(); setShowDeleteDot(false) }}>Cancel</div>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            </Form>
                        </Container>

                    </Modal.Body>
                </div>
            </Modal>
            <br /><br />
        </>
    )
}


export default Dots;