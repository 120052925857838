import React, { useState, useEffect, Component } from 'react'
import { Button, Container, Col, Row, Modal, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios"
import Alert from 'react-bootstrap/Alert';
import { Routes, Route, useLocation } from "react-router-dom"
import { MdNotificationsNone, MdOutlineNotificationImportant } from 'react-icons/md'

import { Link } from "react-router-dom"



export async function generalPost(formData, endpoint) {

  axios.defaults.withCredentials = true


  let response = await axios('https://api.sbhelper.com/' + endpoint, {
    method: 'post',
    data: formData,
    headers: { 'Content-Type': 'application/json' }
  })
    .then(function (response) {

      if (response.status === 200) {
        const rData = response.data;
        return rData;
      }
    })
    .catch(function (error) {
      return { "message": error, "type": "error" }
    })

  return response;
}

export async function signinPost(formData, endpoint) {

  axios.defaults.withCredentials = true

  let response = await axios('https://api.sbhelper.com/' + endpoint, {
    method: 'post',
    data: formData,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
    .then(function (response) {

      if (response.status === 200) {
        if (Object.hasOwn(response.data, "detail")) { var rData = response.data.detail } else { var rData = response.data; }
        if (Object.hasOwn(response.data, "access_token")) {
          return { "message": "success", "type": "success" }
        }

        return rData;
      } else {
        localStorage.clear();
        return response
      }
    })
    .catch(function (error) {

      if (error.status === 401) { return { "message": "Invalid credentials", "type": "error" } }
      return { "message": "Login error", "type": "error" }
    })

  return response
}

export function ApiPing() {
  //get user details here
  const apiPing = async () => {
    axios.defaults.withCredentials = true
    axios.get("https://api.sbhelper.com/ping", { withCredentials: true })
      .then(res => {
        if (res.status === 200) {
          const result = res.data;
          return result.pong
        }
      }).catch(error => {
        return 0
      })

  }

  apiPing()

};

export function Logout() {
  axios.defaults.withCredentials = true
  axios.get('https://api.sbhelper.com/auth/logout')
    .then(res => {
      if (res.status === 200) {
        ResponseMessage({ "message": "Signing out...", "type": "warn" })
        setTimeout(() => {
          ResponseMessage({ "message": "Reloading...", "type": "warn" })
        }, 500);
        setTimeout(() => {
          return window.location.reload()
        }, 1000);

      }
    }
    )
  return
}



export async function GetAccountAssetDetailsList() {

  let algoWallet = GetWalletConnectAlgoWallet();

  async function GetAccountAssetDetailsListNow(algoWallet) {
    await axios.get("https://api.freckletoken.com/wallet/assets?algoWallet=" + algoWallet)
      .then(res => {
        if (res.status === 200) {
          const result = res.data;
          localStorage.setItem('accountAssetListDetails', JSON.stringify(result.pageContent));
        }

      })
      .catch(error => {
        return '';
      });

  }


  if (algoWallet.length === 58) {
    GetAccountAssetDetailsListNow(algoWallet);
  }


  return '';
}


export function LoadingPage() {
  return (<div id="loadingContainer">
    <img src="https://cdn.sbhelper.com/freckletoken/images/freckle/icon.png" height="auto" width="150px" alt="Freckle Logo" className="imageSpin" />
    <div>Loading...</div>
  </div>)
}

export function StandbyPage() {
  return (<div id="loadingBackground">
    <div id="loadingContainer">
      <img src="https://cdn.sbhelper.com/freckletoken/images/freckle/icon.png" height="auto" width="150px" alt="Freckle Logo" className="imageSpin" />
      <div>Standby...</div>
      <br />
      <div className="small standbyDelayMsg">If this is taking more then 30 seconds, there might be an error. Try to refresh your page.</div>
    </div>
  </div>
  )
}

export function RestrictedPage() {
  return (<div id="loadingBackground">
    <div id="loadingContainer">
      <img src="https://cdn.sbhelper.com/freckletoken/images/freckle/icon.png" height="auto" width="150px" alt="Freckle Logo" className="imageSpin" />
      <div>Something went wrong. Perhaps you are clicking too fast or there are too many connections from your location. Please wait about 10 seconds.</div>
      <br />
      <div>If the page doesn't automatically reload, you should refresh the page.</div>
      <br />
      <div>If this continues, you might be doing something wrong or there might be an app error.</div>
    </div>
  </div>
  )
}

export function MaintenancePage() {
  return (<div id="loadingBackground">
    <div id="loadingContainer">
      <img src="https://cdn.sbhelper.com/freckletoken/images/freckle/icon.png" height="auto" width="150px" alt="Freckle Logo" className="imageSpin" />
      <div>Maintenance...</div>
    </div>
  </div>
  )
}

export function IdlePage() {
  return (<div id="loadingBackground">
    <div id="loadingContainer">
      <img src="https://cdn.sbhelper.com/freckletoken/images/freckle/icon.png" height="auto" width="150px" alt="Freckle Logo" className="imageSpin" />
      <div>You have been inactive. To continue, you must perform an action - move your mouse, click a button, touch your screen, etc.</div>
    </div>
  </div>
  )
}


export function CreatingSession() {
  return (<div id="loadingBackground">
    <div id="loadingContainer">
      <img src="https://cdn.sbhelper.com/freckletoken/images/freckle/icon.png" height="auto" width="150px" alt="Freckle Logo" className="imageSpin" />
      <div>Connecting to Freckle and validating your session...</div>
    </div>
  </div>
  )
}


export function ServerMessage({ Message }) {

  if (Message !== '') {
    return (
      <>
        <div className="serverMessageSpacing"></div>
        <div className="serverMessage">
          <Alert variant="warning">
            {Message}
          </Alert>
        </div>
      </>
    )
  }

  return ''

}


export function SetTempResponseMessage(Message, Type) {

  localStorage.setItem('tempResponseMessage', '{"message":"' + Message + '","type":"' + Type + '","time":' + Date.now() + '}')

  return ''
}

export function TempResponseMessage() {

  try {
    if (localStorage.getItem('tempResponseMessage')) {

      if ((Date.now() - JSON.parse(localStorage.getItem('tempResponseMessage')).time) >= '8000') { localStorage.setItem('tempResponseMessage', ''); return ''; }

      function ClearMessage() {
        localStorage.setItem('tempResponseMessage', '');
        return '';
      }

      return (
        <>
          <div className="tempResponseMessageSpacing"></div>
          <div className="tempResponseMessage">
            <Alert variant={JSON.parse(localStorage.getItem('tempResponseMessage')).type} onClose={() => ClearMessage()} dismissible onClick={() => { ClearMessage() }}>
              {JSON.parse(localStorage.getItem('tempResponseMessage')).message}
            </Alert>
          </div>
        </>
      )
    }
  } catch (Exception) { return 'There was an error.' }

  return ''

}

export function ResponseMessage(response) {

  try {
    if (response.message && response.type) {
      let message = response.message
      let type = response.type

      toast[type](message, { theme: "colored" })

    }

  } catch {
    toast.error("Error processing response notification")
  }


}

export function AccountNotification() {
  const location = useLocation();

  if (!IsSignedIn()) { return }
  if (1 == 0) { return <Link className="quickActionBar_Button" to={location.pathname}><MdOutlineNotificationImportant /></Link> }

  return <Link className="quickActionBar_Button" to={location.pathname}><MdNotificationsNone /></Link>
}

export function AccountMessages() {
  const location = useLocation();

  if (!IsSignedIn()) { return }
  if (1 == 0) { return <Link className="quickActionBar_Button" to={location.pathname}><BiMessageRoundedDots /></Link> }

  return <Link className="quickActionBar_Button" to={location.pathname}><BiMessageRounded /></Link>
}

export function AccountMissionMap() {
  const location = useLocation();

  if (!IsSignedIn()) { return }

  return <Link className="quickActionBar_Button" to={location.pathname}><BiMapAlt /></Link>
}


export function NotSignedIn() {
  return (
    <>
      <br /><br />

      <Container>
        <Row>
          <h1>Dots</h1>
        </Row>
        <Row>
          <div>You need to sign in...</div>
        </Row>

      </Container>



      <br /><br />
    </>
  )
}


export function TimestampConverter({ timestamp }) {
  const date = new Date(timestamp);
  const convert_date = new Date(date - (date.getTimezoneOffset() * 60000))
  const d = convert_date.toLocaleDateString()
  const t = convert_date.toLocaleTimeString()
  return (
    <span>{d} {t}</span>
  );
}


export function CopyToClipboard(value) {
  navigator.clipboard.writeText(value);
  ResponseMessage({ "message": "Copied to Clipboard!", "type": "success" })
}