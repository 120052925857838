import "react-toastify/dist/ReactToastify.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import React, { lazy, Suspense, useState, useEffect, useContext } from 'react'
import axios from "axios"
import { Link, Routes, Route, useLocation } from "react-router-dom"
import { ToastContainer } from 'react-toastify'
import AuthContext from "../contexts/AuthContext"
import { BiRefresh } from 'react-icons/bi'

import Navbar from '../components/Navbar'
import ScrollToTop from '../components/ScrollToTop'
import Footer from './Footer'

//import Profile from '../components/Profile'

import Contact from '../pages/Contact'
import HomePage from '../pages/HomePage'
import Pages from '../pages/Pages'
import Pricing from '../pages/Pricing'
import SignOut from '../pages/SignOut'
import TempPage from '../pages/TempPage'
import { ResponseMessage } from "../components/BaseFunctions"
import { LogoutPage } from "../components/Helpers"

import Dots from '../components/apps/Dots'

//import TestPage from '../pages/TestPage'
//import Articles from '../pages/Articles'
//import Tools from '../pages/Tools'
//import { BiRefresh } from 'react-icons/bi'


function Theme() {

  const location = useLocation();
  const routesData = [
    { path: '/', component: HomePage },
    { path: '/contact', component: Contact },
    { path: '/pricing', component: Pricing },
    { path: '/temp-page', component: TempPage },
    { path: '/dots', component: Dots },
    { path: '/*', component: Pages },
  ];

  const auth = useContext(AuthContext);
  const abortController = new AbortController();
  const [authentication, setAuthentication] = useState(auth.authentication);
  const [userDetails, setUserDetails] = useState(auth.userDetails);
  const [doLogout, setDoLogout] = useState(auth.doLogout);
  const [showLogout, setShowLogout] = useState(false)


  async function ping() {
    axios.defaults.withCredentials = true
    axios.get("https://api.sbhelper.com/ping", {
      signal: abortController.signal,
    })
      .then(res => {
        if (res.status === 200) {
          const result = res.data;
          if (!result.pong > 0) { setAuthentication(0) } else { if (!doLogout) { login() } }
          return result.pong;
        }
      }).catch(error => {
        if (error.name === "AbortError");
        setAuthentication(0)
        ResponseMessage({ "message": "API Connection Issue", "type": "warn" })
        return 0
      })

  }


  function login() {
    //get user details here
    const getUserAccountDetails = async () => {
      axios.defaults.withCredentials = true
      axios.get("https://api.sbhelper.com/ping", {
        signal: abortController.signal,
      })
        .then(res => {
          if (res.status === 200) {
            const pong = res.data.pong;
            if (pong > 0) {
              axios.get("https://api.sbhelper.com/auth/user-details", {
                signal: abortController.signal,
              })
                .then(res => {
                  if (res.status === 200) {
                    const result = res.data;
                    setAuthentication(1)
                    setDoLogout(false)
                    setUserDetails(result)
                  }
                }).catch(error => {
                  if (error.name === "AbortError");
                })
            }
            else {
              setAuthentication(0)
            }
          } else {
            ResponseMessage({ "message": "API Connection Issue", "type": "warn" })
          }
        })
    }

    getUserAccountDetails()
  };


  async function logout() {
    abortController.abort();
    setDoLogout(true)
    setShowLogout(true)
    axios.defaults.withCredentials = true
    await axios.get('https://api.sbhelper.com/auth/logout')
      .then(res => {
        if (res.status === 200) {
          return window.location.replace("/")
        }
      }
      )
  };


  useEffect(() => {
    //if (doLogout) { abortController.abort(); }
    if (!doLogout) {
      ping()
    }
  }, [doLogout]);

  useEffect(() => {
    setDoLogout(false)
  }, []);

  //console.log(abortController)


  function spin(e) {
    e.target.classList.add("disabled");
    e.target.classList.add("animate-spin");
    setTimeout(() => {
      e.target.classList.remove("animate-spin");
      e.target.classList.remove("disabled");
    }, 1000);
  }


  return (

    <>
      <AuthContext.Provider value={{ authentication: authentication, logout: logout, doLogout: doLogout, login: login, ping: ping, userDetails: userDetails }}>
        <Navbar />
        <div className="btn-page-container">{location.pathname != "/" & location.pathname != "/pricing" ? <Link className="btn-page-refresh" to={location.pathname} onClick={(e) => { spin(e) }}><BiRefresh /></Link> : <span></span>}</div>
        <div id="page-container" className="page-container">
          <Routes>
            {routesData.map((route, index) => (
              <Route key={index} path={route.path} element={<route.component />} />
            ))}
          </Routes>
        </div>

        <Footer />
        <ScrollToTop />

        <div className="toast-container-x">
          <ToastContainer position="top-center" autoClose={2000} />
        </div>
        {showLogout ? <LogoutPage /> : <></>}
      </AuthContext.Provider >
    </>

  )
}



export default Theme;

